import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Confirm,
  Eventcalendar,
  formatDate,
  MbscCalendarColor,
  MbscCalendarEvent,
  MbscCalendarEventData,
  MbscEventcalendarView,
  MbscEventCreatedEvent,
  MbscEventCreateFailedEvent,
  MbscEventDeletedEvent,
  MbscEventUpdatedEvent,
  MbscEventUpdateFailedEvent,
  MbscResource,
  momentTimezone,
  setOptions,
  Toast
} from "@mobiscroll/react";
import {
  Chip,
  Pagination,
  Skeleton,
  Box,
  IconButton,
  Stack,
  TextField,
  Autocomplete,
  FormControl,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  Avatar,
  Button,
  FormControlLabel,
  Switch
} from "@mui/material";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import "./interviewers.availability.css";
import moment from "moment-timezone";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { InterviewersFilterDto, Tag } from "../../../@generated/graphql";
import { useQuery } from "@apollo/client";
import { useQuery as reactUseQuery } from "react-query";
import { GET_INTERVIEWERS } from "../../Interviewers/services/interviewers.gql";
import { useGetAvailabilityForInterviewers } from "./hooks";
import { AutobookViewApiPayload, IWorkerSelect } from "@rooster/types";
import { useSelector } from "react-redux";
import { getEmailDomain } from "@rooster/utils";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import ClearIcon from "@mui/icons-material/Clear";
import { debounce } from "lodash";
import TagsSelect from "../../Tags/tags.select.component";
import { get } from "../../../utilities/restClient";
import { RoosterCalendarApplicationDetails } from "../../../contexts/events/types";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

momentTimezone.moment = moment;

setOptions({
  theme: "ios",
  themeVariant: "light"
});

const InterviewersAvailability = (props: {
  duration: number;
  startDate: Date;
  timeZone: string;
  selected: IWorkerSelect[];
  applicationDetails?: RoosterCalendarApplicationDetails;
  onCancel: () => void;
  onApply: (
    event: {
      startDate: Date;
      startTimeInMins: number;
      duration: number;
    },
    selected: IWorkerSelect[]
  ) => void;
}): JSX.Element => {
  const {
    duration = 60,
    startDate = new Date(),
    timeZone = moment.tz.guess(),
    selected = [],
    applicationDetails,
    onCancel,
    onApply
  } = props ?? {};
  const [myEvents, setMyEvents] = useState<MbscCalendarEvent[]>([]);
  const eventDuration = duration < 30 ? 30 : duration;
  const [isToastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [tempEvent, setTempEvent] = useState<MbscCalendarEvent>();
  const [isNewEvent, setIsNewEvent] = useState<boolean>(false);
  const [interviewerFilterParams, setInterviewerFilterParams] =
    useState<InterviewersFilterDto>({
      page: 1,
      perPage: 12,
      searchTerm: "",
      tags: [],
      departments: []
    });
  const {
    loading: interviewersLoading,
    data: interviewersResponse,
    refetch: interviewersRefetch
  } = useQuery(GET_INTERVIEWERS, {
    variables: {
      filter: interviewerFilterParams
    }
  });
  const authUser = useSelector(
    (state: any) => (state?.sessionState?.authUser as Record<string, any>) ?? {}
  );

  const domains =
    useSelector((state: any) => state.domainsReducer.domains) ?? {};

  const [availabilityFilterParams, setAvailabilityFilterParams] = useState<{
    client: string;
    queryWindow: AutobookViewApiPayload["queryWindows"][0];
    requesterEmail: AutobookViewApiPayload["requesterEmail"];
    queryResources: AutobookViewApiPayload["queryResources"];
    tzid: AutobookViewApiPayload["responseFormat"]["tzid"];
    page: number;
    duration: number;
  } | null>(null);

  const startDateInTimezone = moment(startDate)
    .clone()
    .tz(timeZone)
    .startOf("day");
  const endTimeInTimezone = startDateInTimezone.clone().add(2, "weeks");
  const queryWindow = {
    timeMin: startDateInTimezone.toISOString(),
    timeMax: endTimeInTimezone.toISOString()
  };
  const { data: availabilityData, isFetching: availabilityLoading } =
    useGetAvailabilityForInterviewers(availabilityFilterParams);

  const { data: departmentsResponse } = reactUseQuery(
    ["CLIENT_DEPARTMENTS"],
    async () => {
      return get(`${import.meta.env.REACT_APP_URL_API}/tenants/departments`);
    }
  );

  const { data: availabilityResponse } = reactUseQuery(
    ["CANDIDATE_AVAILABILITY"],
    async () => {
      return get(
        `${import.meta.env.REACT_APP_URL_API}/applications/availability/${
          applicationDetails?.jobApplicationId
        }`
      );
    },
    {
      enabled: !!applicationDetails?.jobApplicationId
    }
  );

  useEffect(() => {
    if (availabilityResponse?.data?.availability?.length > 0) {
      const availableSlots: any = [];
      const clientTimeZone =
        availabilityResponse?.data?.clientTimezone ?? timeZone;
      const allSlots: any = [];

      availabilityResponse?.data?.availability.forEach(slot => {
        const start = moment(slot.start).tz(clientTimeZone);
        const end = moment(slot.end).tz(clientTimeZone);
        while (start.isBefore(end)) {
          let slotDuration =
            duration % 60 !== 0
              ? duration
              : start.minutes() === 0
              ? duration
              : 30;
          const slotEnd = start.clone().add(slotDuration, "minutes");
          if (slotEnd.isAfter(end)) {
            slotDuration = 30;
          }
          availableSlots.push({
            start: start.clone().toDate(),
            end: start.clone().add(slotDuration, "minutes").toDate(),
            title: `${start.clone().tz(clientTimeZone).format("hh:mm A")}`,
            available: true,
            background: "#c9e8d1",
            resource: applicationDetails?.candidateEmail
          });
          start.add(slotDuration, "minutes");
        }
      });

      let current = startDateInTimezone.clone();
      while (current.isBefore(endTimeInTimezone)) {
        allSlots.push({
          start: current.clone().toDate(),
          end: current.clone().add(duration, "minutes").toDate(),
          title: `${current.clone().tz(clientTimeZone).format("hh:mm A")}`,
          background: "#ffbaba4d",
          available: false,
          resource: applicationDetails?.candidateEmail
        });
        current.add(duration, "minutes");
      }

      // Filter allSlots to remove any slots that overlap with availableSlots
      const filteredAllSlots = allSlots?.filter(slot => {
        return !availableSlots?.some(availableSlot => {
          const slotStart = moment(slot.start);
          const slotEnd = moment(slot.end);
          const availableSlotStart = moment(availableSlot.start);
          const availableSlotEnd = moment(availableSlot.end);

          return (
            slotStart.isBefore(availableSlotEnd) &&
            slotEnd.isAfter(availableSlotStart)
          );
        });
      });

      const candidateResource = {
        id: applicationDetails?.candidateEmail ?? "",
        name: applicationDetails?.candidateName,
        email: applicationDetails?.candidateEmail,
        timezone: clientTimeZone,
        utcOffset: `${clientTimeZone}`,
        organizer: false,
        candidate: true,
        fixed: true
      };
      setFixedResourceDetails({
        ...fixedResourceDetails,
        [candidateResource?.id]: {
          colors: [...availableSlots, ...filteredAllSlots]
        }
      });
      if (!fixedResources.some(fr => fr.id === candidateResource.id)) {
        setFixedResources([candidateResource, ...fixedResources]);
      }
    }
  }, [availabilityResponse]);

  useEffect(() => {
    if (
      interviewersResponse &&
      interviewersResponse?.interviewers?.interviewers?.length > 0 &&
      authUser?.client &&
      Object.keys(domains).length > 0
    ) {
      const queryResources =
        interviewersResponse?.interviewers?.interviewers?.map(interviewer => ({
          type: "interviewer",
          value: interviewer.email,
          provider: domains?.[getEmailDomain(interviewer?.email)]?.provider
        }));
      if (selected.length > 0) {
        queryResources.push(
          ...selected.map(s => ({
            type: "interviewer",
            value: s.email,
            provider: domains?.[getEmailDomain(s.email)]?.provider
          }))
        );
      }
      setAvailabilityFilterParams({
        ...(availabilityFilterParams ?? {}),
        client: authUser.client,
        queryWindow,
        duration: eventDuration,
        queryResources,
        requesterEmail: authUser.email,
        tzid: timeZone,
        page: 1
      });
    }
  }, [interviewersResponse?.interviewers, authUser, domains]);

  const [page, setPage] = useState(1);
  const [considerCandidateAvailability, setConsiderCandidateAvailability] =
    useState<boolean>(true);
  const resources: MbscResource[] = useMemo(() => {
    const interviewers = interviewersResponse?.interviewers?.interviewers;
    if (availabilityData && interviewers) {
      return interviewers?.map(interviewer => {
        const availability = availabilityData.queryWindowsResult.find(
          x => x.participant === interviewer.email
        );
        const timezone =
          availability?.interviewerPreference?.availability?.timezone ?? "utc";
        return {
          id: interviewer.email,
          name: interviewer.name,
          email: interviewer.email,
          timezone,
          utcOffset: `${timezone}`,
          organizer: false,
          worker: interviewer
        };
      });
    }
    return [];
  }, [availabilityData, interviewersResponse?.interviewers]);

  const [myResources, setMyResources] = useState<MbscResource[]>(resources);
  const [fixedNr, setFixedNr] = useState<number>(0);
  const [fixedResources, setFixedResources] = useState<MbscResource[]>([]);
  const [fixedResourceDetails, setFixedResourceDetails] = useState<{
    [key: string]: {
      colors: MbscCalendarColor[];
    };
  }>({});

  const myView = useMemo<MbscEventcalendarView>(
    () => ({
      timeline: {
        type: "week",
        timeCellStep: eventDuration,
        timeLabelStep: 1440
      }
    }),
    []
  );

  const details = useMemo(() => {
    const colors: MbscCalendarColor[] = [];

    availabilityData?.queryWindowsResult?.forEach(queryWindow => {
      queryWindow.availabilityView?.forEach(view => {
        const date = moment(view.date).clone().tz(timeZone);

        const startOfDay = moment(date).startOf("day");
        const endOfDay = moment(date).endOf("day");

        while (startOfDay.isBefore(endOfDay)) {
          const slotStartHour = startOfDay.hour();
          const slotStartMinutes = startOfDay.minutes();
          const slotStart = startOfDay.clone();
          const slotEnd = startOfDay.add(eventDuration, "minutes");
          const data = view.availability?.find(
            slot =>
              slot.hour === slotStartHour && slot.minute === slotStartMinutes
          );
          const slotStartUTC = slotStart.clone().utc();
          const participantTimezone =
            queryWindow.interviewerPreference?.availability?.timezone ?? "utc";

          const slotStartParticipantTime = slotStartUTC
            .clone()
            .tz(participantTimezone)
            .format("hh:mm A");

          colors.push({
            start: slotStart.toDate(),
            end: slotEnd.toDate(),
            title: slotStartParticipantTime,
            available: data?.available && !data?.outsideOfWorkingHours,
            background: data?.available
              ? "#c9e8d1"
              : data?.outsideOfWorkingHours
              ? "#f7f7bb4d"
              : "#ffbaba4d",
            resource: queryWindow.participant
          });
        }
      });
    });

    return { colors };
  }, [resources, availabilityData]);

  const fixedResourcesColors = useMemo(() => {
    const colors: MbscCalendarColor[] = [];
    fixedResources.forEach(resource => {
      if (!resources?.find(r => r.id === resource.id)) {
        const resourceDetails = fixedResourceDetails[resource.id];
        if (resourceDetails) {
          colors.push(...(resourceDetails?.colors ?? []));
        }
      }
    });
    return colors;
  }, [fixedResources, fixedResourceDetails, resources]);

  const myScheduleEvent = useCallback((data: MbscCalendarEventData) => {
    const start = (data.startDate as MyDate).clone();
    const end = (data.endDate as MyDate).clone();

    start.setTimezone(data.currentResource!.timezone);
    end.setTimezone(data.currentResource!.timezone);

    return (
      <div
        className="md-meeting-planner-cont"
        style={{ background: data.color }}
      >
        <div className="md-meeting-planner-wrapper">
          <div className="md-meeting-planner-title">{data.title}</div>
          <div className="md-meeting-planner-time">
            {formatDate("hh:mm A", start)} - {formatDate("hh:mm A", end)}
          </div>
        </div>
      </div>
    );
  }, []);

  useEffect(() => {
    if (resources?.length === 0) {
      setMyResources([]);
    }
    setMyResources([
      ...fixedResources,
      ...resources?.filter(r => !fixedResources?.some(fr => fr.id === r.id))
    ]);
  }, [resources, fixedResourceDetails, fixedResources]);

  useEffect(() => {
    if (fixedResources.length > 0) {
      if (details) {
        fixedResources.forEach(resource => {
          if (!fixedResourceDetails[resource.id]) {
            const resourceDetails = details.colors.filter(
              color => color.resource === resource.id
            );
            if (resourceDetails.length > 0) {
              setFixedResourceDetails({
                ...fixedResourceDetails,
                [resource.id]: { colors: resourceDetails }
              });
            }
          }
        });
      }
      if (availabilityData && fixedResources?.some(r => !r.timezone)) {
        const updatedFixedResources = fixedResources.map(resource => {
          if (!resource.timezone) {
            const availability = availabilityData.queryWindowsResult.find(
              x => x.participant === resource.id
            );
            const timezone =
              availability?.interviewerPreference?.availability?.timezone ??
              "utc";
            return {
              ...resource,
              timezone,
              utcOffset: `UTC ${moment().tz(timezone).format("Z")}`
            };
          }
          return resource;
        });
        setFixedResources(updatedFixedResources);
      }
    }
  }, [details, fixedResources, availabilityData]);

  useEffect(() => {
    if (selected?.length > 0) {
      const selectedData = selected?.map(interviewer => {
        return {
          id: interviewer.email,
          name: interviewer.name,
          email: interviewer.email,
          organizer: false,
          fixed: true,
          worker: interviewer
        };
      });
      setFixedResources([...selectedData]);
    }
  }, [selected]);

  const myHeader = () => (
    <>
      <CalendarNav />
      <div className="md-meeting-planner-header">
        <div className="md-meeting-planner-zone md-meeting-planner-work">
          non working hours
        </div>
        <div className="md-meeting-planner-zone md-meeting-planner-flex">
          available
        </div>
        <div className="md-meeting-planner-zone md-meeting-planner-off">
          busy
        </div>
        <CalendarPrev />
        <CalendarToday />
        <CalendarNext />
      </div>
    </>
  );

  const getEventsForFixedResources = (
    resources: MbscResource[],
    slot: MbscCalendarEvent
  ) => {
    return resources?.map(fr => ({
      start: slot.start,
      end: moment(slot.start)?.clone().add(eventDuration, "minutes"),
      title: "Selected Slot",
      color: "#1ad404",
      resource: fr.id
    }));
  };

  const handleEventCreateOrUpdate = useCallback(
    (args: MbscEventCreatedEvent | MbscEventUpdatedEvent) => {
      //Check if event start is in the past
      if (moment(args.event.start).isBefore(moment())) {
        setToastMessage("Event cannot be created in the past");
        setToastOpen(true);
        return false;
      }
      const resource = args?.event?.resource;
      if (!fixedResources?.some(r => r.id === resource)) {
        setToastMessage(
          "Event can only be created for selected interviewer(s)"
        );
        setToastOpen(true);
        return false;
      }
      const events = getEventsForFixedResources(fixedResources, args?.event);
      setMyEvents(events);
      setTimeout(() => {
        setToastMessage("Event created");
        setToastOpen(true);
      });
      return false;
    },
    [fixedResources]
  );

  const handleEventDeleted = useCallback(() => {
    setMyEvents([]);
  }, [myEvents]);

  const isSameEvent = (
    event1: MbscCalendarEvent,
    event2: MbscCalendarEvent
  ) => {
    if (!event1 || !event2) return false;
    return (
      moment(event1.start).valueOf() === moment(event2.start)?.valueOf() &&
      moment(event1.end).valueOf() === moment(event2.end)?.valueOf()
    );
  };
  const commonAvailableSlots: MbscCalendarColor[] = useMemo(() => {
    let availableSlots: MbscCalendarColor[] | null = null;
    fixedResources
      ?.filter(fr => considerCandidateAvailability || !fr.candidate)
      ?.forEach(resource => {
        const resourceDetails = fixedResourceDetails[resource.id];
        if (resourceDetails) {
          const resourceColors = resourceDetails?.colors?.filter(
            color => !!color.available
          );
          if (availableSlots === null) {
            availableSlots = [...resourceColors];
          } else {
            availableSlots = availableSlots?.filter(slot => {
              return resourceColors?.some(color => {
                return isSameEvent(color, slot);
              });
            });
          }
        }
      });
    return availableSlots ?? [];
  }, [fixedResources, fixedResourceDetails, considerCandidateAvailability]);

  const handleCloseToast = useCallback(() => setToastOpen(false), []);

  const handleConfirmClose = useCallback(
    (res: boolean) => {
      if (res) {
        if (isNewEvent) {
          setMyEvents([...myEvents, tempEvent!]);
        } else {
          const index = myEvents.findIndex(x => x.id === tempEvent!.id);
          const newEventList = [...myEvents];

          newEventList.splice(index, 1, tempEvent!);
          setMyEvents(newEventList);
        }
        setToastMessage(isNewEvent ? "Event created" : "Event updated");
        setToastOpen(true);
      }
      setConfirmOpen(false);
    },
    [isNewEvent, myEvents, tempEvent]
  );

  const toggleComparison = useCallback(
    (resource: MbscResource) => {
      const isFixed = resource.fixed;
      const origResource = myResources.find(r => r.id === resource.id)!;
      let newFixedResources: any = [];
      let newFixedNr = fixedNr;

      if (!isFixed) {
        origResource!.fixed = true;
        newFixedResources = [...fixedResources, origResource];
        newFixedNr++;
      } else {
        origResource!.fixed = false;
        newFixedResources = fixedResources.filter(r => r.id !== resource.id);
        newFixedNr--;
      }

      const newResources = [...newFixedResources];
      myResources.forEach(r => {
        if (!r.fixed) {
          newResources.push(r);
        }
      });

      setMyResources(newResources);
      setFixedResources(newFixedResources);
      setFixedNr(newFixedNr);
      setMyEvents([]);
    },
    [fixedNr, fixedResources, myResources]
  );
  const handlePageChange = (_, value: number) => {
    setPage(value);
    setInterviewerFilterParams({
      ...interviewerFilterParams,
      page: value
    });
  };
  const customResource = useCallback(
    (r: MbscResource) => (
      <Stack direction={"row"} sx={{ display: "flex" }}>
        <Box
          sx={{
            flexGrow: 1
          }}
        >
          <Typography>{r.name}</Typography>
          <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
            {r.utcOffset}
          </Typography>
        </Box>
        {!r.candidate && (
          <IconButton
            size="small"
            sx={{ width: 30 }}
            color={r.fixed ? "error" : "success"}
            onClick={() => toggleComparison(r)}
          >
            {r.fixed ? (
              <RemoveCircleOutlineRoundedIcon />
            ) : (
              <AddCircleOutlineRoundedIcon />
            )}
          </IconButton>
        )}
        {r.candidate && <AccountCircleOutlinedIcon color="primary" />}
      </Stack>
    ),
    [fixedNr, toggleComparison]
  );

  const debouncedRefetch = useCallback(
    debounce(() => {
      interviewersRefetch();
    }, 800),
    [interviewersRefetch]
  );
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setInterviewerFilterParams({
      ...interviewerFilterParams,
      searchTerm
    });
    debouncedRefetch();
  };

  const suggestTimeListItemText = (
    fixedResources: MbscResource[],
    slot: MbscCalendarEvent
  ) => {
    return (
      <ListItemText
        primary={
          <Typography component="span" variant="subtitle1" color="primary">
            {moment(slot.start).clone().tz(timeZone).format("lll z")}
          </Typography>
        }
        secondary={
          <>
            {fixedResources
              ?.filter(fr => considerCandidateAvailability || !fr.candidate)
              ?.map((resource, index) => (
                <Stack key={index} direction={"row"} spacing={0.5} pt={0.5}>
                  <Avatar sx={{ width: 18, height: 18 }} alt={resource.name}>
                    {resource.name?.charAt(0)}
                  </Avatar>
                  <Typography
                    color={resource.candidate ? "primary" : "secondary"}
                    variant="subtitle2"
                    sx={{ fontSize: 12, width: 120 }}
                  >
                    {resource.name}
                  </Typography>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    sx={{ fontSize: 12 }}
                  >
                    {moment(slot.start)
                      ?.clone()
                      ?.tz(resource.timezone)
                      ?.format("LT z")}{" "}
                    -{" "}
                    {moment(slot.end)
                      ?.clone()
                      ?.tz(resource.timezone)
                      ?.format("LT z")}
                  </Typography>
                </Stack>
              ))}
          </>
        }
      />
    );
  };
  return (
    <>
      <Stack direction="row" justifyContent="space-between" mx={1}>
        <TextField
          sx={{ m: 0 }}
          label="Search"
          variant="outlined"
          size={"small"}
          margin="normal"
          value={interviewerFilterParams?.searchTerm}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => {
                  setInterviewerFilterParams({
                    ...interviewerFilterParams,
                    searchTerm: ""
                  });
                }}
              >
                <ClearIcon sx={{ fontSize: 13 }} />
              </IconButton>
            )
          }}
          onChange={handleSearch}
        />
        <Stack direction="row" spacing={2}>
          <FormControlLabel
            control={
              <Switch
                checked={considerCandidateAvailability}
                onChange={e => {
                  setConsiderCandidateAvailability(e.target.checked);
                }}
                name="prioritizeCandidate"
                color="primary"
              />
            }
            label="Consider Candidate Availability"
          />
          <FormControl sx={{ minWidth: 200 }}>
            <TagsSelect
              value={[]}
              onChange={(value: Tag[]) => {
                setInterviewerFilterParams({
                  ...interviewerFilterParams,
                  tags: value.map(tag => tag.id)
                });
                debouncedRefetch();
              }}
            />
          </FormControl>
          <Autocomplete
            multiple
            sx={{ minWidth: 300, maxWidth: 300 }}
            options={
              departmentsResponse?.data?.departments?.sort((a, b) =>
                a.localeCompare(b)
              ) ?? []
            }
            size="small"
            onChange={(_, values: string[]) => {
              setInterviewerFilterParams({
                ...interviewerFilterParams,
                departments: values
              });
              debouncedRefetch();
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Filter by Departments"
                variant="outlined"
              />
            )}
          />
        </Stack>
      </Stack>
      {(availabilityLoading || interviewersLoading) && (
        <>
          {Array.from({ length: 10 }).map((_, index) => (
            <Skeleton key={index} height={60} animation="wave" />
          ))}
        </>
      )}
      {!availabilityLoading &&
        !interviewersLoading &&
        myResources?.length === 0 && (
          <Typography
            variant="body1"
            color="textSecondary"
            align="center"
            sx={{ mt: 2 }}
          >
            No interviewers are available for the specified criteria.
          </Typography>
        )}
      <Box
        sx={{
          display: "flex",
          mx: 0.5
        }}
      >
        <Box
          sx={{
            height: "calc(100vh - 160px)",
            flexGrow: 1,
            b: 0.5,
            display:
              availabilityLoading ||
              interviewersLoading ||
              myResources?.length === 0
                ? "none"
                : "block",
            width:
              commonAvailableSlots?.length > 0 ? "calc(100vw - 350px)" : "100%"
          }}
        >
          <Eventcalendar
            timezonePlugin={momentTimezone}
            dataTimezone={timeZone}
            displayTimezone={timeZone}
            clickToCreate={"double"}
            dragToCreate={true}
            dragToMove={true}
            dragToResize={true}
            dragTimeStep={eventDuration}
            view={myView}
            data={myEvents}
            resources={myResources}
            colors={[...fixedResourcesColors, ...details.colors]}
            invalid={[]}
            renderScheduleEvent={myScheduleEvent}
            renderHeader={myHeader}
            renderResource={customResource}
            onEventClick={handleEventDeleted}
            onEventCreate={handleEventCreateOrUpdate}
            onEventUpdated={handleEventCreateOrUpdate}
            onEventDeleted={handleEventDeleted}
            min={startDateInTimezone.toDate()}
            max={endTimeInTimezone.toDate()}
          />
          <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
            <Pagination
              count={Math.ceil(
                (interviewersResponse?.interviewers?.count ?? 0) / 12
              )}
              size="small"
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        </Box>
        {((commonAvailableSlots?.length > 0 &&
          fixedResources?.some(fr => !fr.candidate)) ||
          myEvents?.length > 0) && (
          <Box
            sx={{
              height: "calc(100vh - 160px)",
              overflow: "auto",
              display:
                availabilityLoading || interviewersLoading ? "none" : "block",
              ml: 1
            }}
          >
            <Typography variant="h4">Suggested Times</Typography>
            <List
              sx={{
                width: "100%",
                maxWidth: 350,
                bgcolor: "background.paper",
                p: 0
              }}
            >
              {myEvents?.length > 0 && (
                <>
                  <ListItemButton
                    sx={{
                      px: 0.25,
                      py: 2,
                      background: "#c9e8d1"
                    }}
                    key={"selected-event"}
                    onClick={() => {
                      setMyEvents(
                        fixedResources?.map(fr => ({
                          start: myEvents?.[0].start,
                          end: myEvents?.[0].end,
                          title: "Selected Slot",
                          color: "#1ad404",
                          resource: fr.id
                        }))
                      );
                    }}
                    alignItems="flex-start"
                  >
                    {suggestTimeListItemText(fixedResources, myEvents?.[0])}
                  </ListItemButton>
                </>
              )}

              <Divider />

              {commonAvailableSlots?.map((slot, index) => (
                <>
                  {!isSameEvent(slot, myEvents?.[0]) && (
                    <>
                      <ListItemButton
                        sx={{
                          px: 0.25,
                          py: 2
                        }}
                        key={index}
                        onClick={() => {
                          setMyEvents(
                            fixedResources?.map(fr => ({
                              start: slot.start,
                              end: slot.end,
                              title: "Selected Slot",
                              color: "#1ad404",
                              resource: fr.id
                            }))
                          );
                        }}
                        alignItems="flex-start"
                      >
                        {suggestTimeListItemText(fixedResources, slot)}
                      </ListItemButton>
                      <Divider />
                    </>
                  )}
                </>
              ))}
            </List>
          </Box>
        )}
      </Box>

      <Toast
        message={toastMessage}
        isOpen={isToastOpen}
        onClose={handleCloseToast}
      />
      <Confirm
        isOpen={isConfirmOpen}
        title="Are you sure you want to proceed?"
        message="It looks like someone from the team won't be able to join the meeting."
        okText="Yes"
        cancelText="No"
        onClose={handleConfirmClose}
      />
      <Stack direction="row" justifyContent="flex-end" spacing={2} mt={1}>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => {
            setMyEvents([]);
            if (onCancel) {
              onCancel();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={myEvents?.length === 0}
          onClick={() => {
            if (onApply) {
              const startOfDayInTz = moment(myEvents?.[0].start)
                .tz(timeZone)
                .startOf("day");
              const startTimeInMinutes = moment(myEvents?.[0].start)
                .tz(timeZone)
                .diff(startOfDayInTz, "minutes");
              const durationMinutes = moment(myEvents?.[0].end).diff(
                moment(myEvents?.[0].start),
                "minutes"
              );
              onApply(
                {
                  startDate: moment(myEvents?.[0].start)
                    .startOf("day")
                    .toDate(),
                  startTimeInMins: startTimeInMinutes,
                  duration: durationMinutes
                },
                fixedResources
                  ?.filter(fr => !fr.candidate)
                  ?.map(fr => ({ ...fr.worker, value: fr.email }))
              );
            }
          }}
        >
          Apply
        </Button>
      </Stack>
    </>
  );
};
export default InterviewersAvailability;

interface MyDate extends Date {
  clone(): MyDate;
  setTimezone(timezone: MyDate): void;
}
